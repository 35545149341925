/* IMPORT */
import Color from '../color/index.js';
/* MAIN */
const isValid = (color) => {
    try {
        Color.parse(color);
        return true;
    }
    catch {
        return false;
    }
};
/* EXPORT */
export default isValid;
